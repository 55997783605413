import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../images/new-home-screenshot.png';
import SvgCharts from '../svg/SvgCharts';
import { Helmet } from "react-helmet"

export default class ContactPage extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    contactMessage: "",
  }

  handleInputChange = event => {  	
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleSubmit = event => {
  	if ((this.state.firstName == "") || (this.state.lastName == "") || (this.state.email == "") || (this.state.contactMessage == ""))
  	{
        event.preventDefault()
        alert(`You must fill out all fields before submitting.`)
  	}    
  }
  render() {
    return (
      <>
      <Helmet title="VUE - Contact US" defer={false} />
      <Layout>
<section className="pt-5 md:pt-5">
     <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-4/5">
        <h1 className="text-4xl lg:text-2xl xl:text-3xl mb-10 font-bold leading-none">Contact Us</h1>
        <p className="text-md md:text-md mt-6 mb-6 font-light">For help, feature requests or project information you can email us at vue@elist.tufts.edu or use the form below.</p>
 <form className="w-full max-w-lg" action="#" onSubmit={this.handleSubmit} method="POST" action="https://formspree.io/mzbdbjby">
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
        First Name
      </label>
      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="firstName" id="grid-first-name" type="text" placeholder="Jane" value={this.state.firstName} onChange={this.handleInputChange}/>      
    </div>
    <div className="w-full md:w-1/2 px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
        Last Name
      </label>
      <input name="lastName" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Doe" value={this.state.lastName} onChange={this.handleInputChange}/>
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
        E-mail
      </label>
      <input name="email" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="email" value={this.state.email} onChange={this.handleInputChange}/>      
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
        Message
      </label>
      <textarea name="contactMessage" className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none" id="message" value={this.state.contactMessage} onChange={this.handleInputChange}></textarea>     
    </div>
  </div>
  <div className="md:flex md:items-center">
    <div className="md:w-1/3">
      <button className="shadow bg-blue-400 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit">
        Send
      </button>
    </div>
    <div className="md:w-2/3"></div>
  </div>
</form>
		</div>
	</div>
</section>
</Layout>
</>
    )
  }
}

